import Layout from "./Layout";
import Logo from "./images/AM/AM logo.png";
import prjOverview from "./images/AM/AM Project Overview.webp";
import Background from "./images/AM/AMbground.webp";
import {
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaBootstrap,
  FaReact,
} from "react-icons/fa6";
import LoadingProject from "../UI/Animations/LoadingProject";
import AMdesk1 from "./images/AM/AMdesk1.webp";
import AMdesk2 from "./images/AM/AMdesk2.webp";
import AMdesk3 from "./images/AM/AMdesk3.webp";
import AMdesk4 from "./images/AM/AMdesk4.webp";
import AMdesk5 from "./images/AM/AMdesk5.webp";
import AMmobile from "./images/AM/AMmobile.webp";

const AMproject = () => {
  const AMDescription = [
    " This project is a website built using React, CSS, JavaScript, and Bootstrap. It features an appealing visual design with a focus on easy navigation and showcasing the client's services.",
    " ",
    " ",
    "The key highlights of this project are as follows:",
    " ",
    "   - Responsive and adaptive navbar that adjusts its style based on the position on the screen.",
    " ",
    "   - Easy navigation interface to ensure a seamless browsing experience.",
    " ",
    "   - A visually appealing layout that highlights the client's services and promotes easy navigation.",
    " ",
    "   - A gallery page showcasing the client's previous work, providing a visual portfolio of their accomplishments.",
    " ",
    "   - Contact page to facilitate communication with potential customers that provide a way to reach out for the client's services.",
    " ",
    "   - Optimized for high performance.",
    " ",
    "   - Optimized for SEO to improve search engine visibility",
    " ",
    " ",
    " Ongoing development to discuss and implement the details of the gallery section, ensuring an enhanced user experience.",
    " ",
  ];

  return (
    <>
      <LoadingProject />
      <Layout
        projectLogo={Logo}
        projectTitle="Full Web Development"
        projectOverview={prjOverview}
        projectDescription={AMDescription}
        background={Background}
        techIcons={
          <>
            <FaReact title="React.js" />
            <FaHtml5 title="HTML" />
            <FaCss3Alt title="CSS" />
            <FaJs title="JavaScript" />
            <FaBootstrap title="Bootstrap" />
          </>
        }
      >
        <h3>Homepage</h3>
        <img src={AMdesk1} alt="AM Homepage1" />
        <img src={AMdesk2} alt="AM Homepage2" />
        <h3>About page</h3>
        <img src={AMdesk3} alt="AM About Page" />
        <h3>Projects showcase page</h3>
        <img src={AMdesk4} alt="AM Projects Page" />
        <h3>Contact page</h3>
        <img src={AMdesk5} alt="AM Contact Page" />
        <h3>Mobile</h3>
        <img src={AMmobile} alt="AM Mobile" />
      </Layout>
    </>
  );
};

export default AMproject;
