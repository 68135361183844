import "./Loading.css"
import Logo from "../../img/MARCOS W.webp"

const Loading = () => {
  return (
    <div className="loadingContainer">
        <img src={Logo} alt="MarcosFraga Logo"/>
        <div className="loading">
      <p className="typed">Loading my desk </p><p className="dots"><span className="loader__dot">. </span><span className="loader__dot">. </span><span className="loader__dot">. </span></p></div>
    </div>
  );
};

export default Loading;
