import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import App from "./App";
import AMproject from "./components/projects/AM-project";
import FTCproject from "./components/projects/FTC-project";
import MFproject from "./components/projects/MF-project";
import OtherProjects from "./components/projects/OtherProjects";
import LawPawProject from "./components/projects/LawPawProject";
import AzulejoIMMO from "./components/projects/AzulejoIMMO";

const AppRouter = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route index element={<App />} />
          <Route exact path="/index.html" render={() => (<Navigate  to="https://www.marcosfraga.ch" />)} />
          <Route path="projects/AM" element={<AMproject />} />
          <Route path="projects/FTC" element={<FTCproject />} />
          <Route path="projects/MarcosFraga" element={<MFproject />} />
          <Route path="projects/OtherProjects" element={<OtherProjects />} />
          <Route path="projects/LawPaw" element ={<LawPawProject />} />
          <Route path="projects/AzulejoIMMO" element ={<AzulejoIMMO />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default AppRouter;
