import Particules from "../UI/Animations/Particules";
import Letter from "../UI/Animations/LetterAnimation";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <section id="contact" className="contact-page">
      <Particules />
      <div className="contact-container">
        <div className="text-container">
          <h2>
            <Letter className="coolPort" index={0}>
              C
            </Letter>
            <Letter className="coolPort" index={1}>
              o
            </Letter>
            <Letter className="coolPort" index={2}>
              n
            </Letter>
            <Letter className="coolPort" index={3}>
              n
            </Letter>
            <Letter className="coolPort" index={4}>
              e
            </Letter>
            <Letter className="coolPort" index={5}>
              c
            </Letter>
            <Letter className="coolPort" index={6}>
              t
            </Letter>
            <span className="connect space">*</span>
            <Letter className="coolPort" index={7}>
              w
            </Letter>
            <Letter className="coolPort" index={4}>
              i
            </Letter>
            <Letter className="coolPort" index={5}>
              t
            </Letter>
            <Letter className="coolPort" index={6}>
              h
            </Letter>
            <span className="connect space">*</span>
            <Letter className="coolPort" index={5}>
              m
            </Letter>
            <Letter className="coolPort" index={6}>
              e
            </Letter>
          </h2>
          <h3>
            Thank you for stopping by! I'm currently seeking to join a team of
            creative developers. If you think I'd be a good fit or if I can
            assist you with a project, feel free to connect with me.
          </h3>
        </div>
        <div className="links-container">
          <div className="email">
            <h4>Email</h4>
            <a href="mailto:marcos@marcosfraga.ch">
              <span>marcos@marcosfraga.ch</span>
            </a>
          </div>
          <div className="social">
            <h4>Socials</h4>
            <div className="links">
              <a
                href="https://www.linkedin.com/in/marcosfraga/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
              <a
                href="https://www.instagram.com/mfraga87/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://twitter.com/mfragaprt"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter />
              </a>
            </div>
          </div>
        </div>
        <p className="freelance-section">
          Available for select freelance opportunities.
        </p>
      </div>
    </section>
  );
};

export default ContactPage;
