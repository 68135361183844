import "./LoadingProject.css"
import Logo from "../../img/MARCOS W.webp"


const LoadingProject = () => {
  return (
    <div className="loadingContainerP">
    <img src={Logo} alt="MarcosFraga Logo"/>
        <div className="loadingP">
      <p className="typedP">Loading project </p><p className="dotsP"><span className="loader__dotP">. </span><span className="loader__dotP">. </span><span className="loader__dotP">. </span></p></div>
    </div>
  );
};

export default LoadingProject;
