import React from "react";
import "./ScrollDownIcon.css";

const ScrollIcon = () => {
  return (
    <>
      <div className="containerScroll">
        <svg
          width="40px"
          height="100%"
          viewBox="0 0 247 390"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className="scroll-icon-svg"
        >
          <path
            id="wheel"
            d="M123.359,79.775l0,72.843"
            className="scroll-icon-wheel"
          />
          <path
            id="mouse"
            d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
            className="scroll-icon-mouse"
          />
        </svg>
        <p>Scroll down</p>
      </div>
    </>
  );
};

export default ScrollIcon;
