import React, { useEffect, useRef, useState } from "react";
import "./AboutPage.css";
import Foto from "../img/AI Photo.webp";
import Letter from "../UI/Animations/LetterAnimation";
import Button from "../UI/DownloadButton";
import ScrollToTopButton from "../UI/ScrollToTopButton";
import { FaDownload } from "react-icons/fa";
import "../UI/Animations/LetterAnimationTXT.css";
import awardImg from "../img/award1.png";

const AboutPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  const handleScroll = () => {
    const element = imgRef.current;
    if (element) {
      const { top } = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const margin = -200;
      if (top < windowHeight - margin) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const addClassToBody = setTimeout(() => {
      document.body.classList.add("overflowBody");
    });

    const removeClassFromBody = setTimeout(() => {
      document.body.classList.remove("overflowBody");
    }, 10000);

    return () => {
      clearTimeout(addClassToBody);
      clearTimeout(removeClassFromBody);
    };
  }, []);

  return (
    <section id="about" className="about-page">
      <div className="container">
        <div className={`foto-section ${isVisible ? "slideIn-right" : ""}`}>
          <img
            className="profileFoto"
            src={Foto}
            alt="Profile Foto"
            ref={imgRef}
          />
        </div>
        <div className="text-section">
          <h2
            className={isVisible ? "anim-delay1 slideIn-left" : ""}
            ref={imgRef}
          >
            <Letter className="coolAbout" index={0}>
              A
            </Letter>
            <Letter className="coolAbout" index={1}>
              b
            </Letter>
            <Letter className="coolAbout" index={2}>
              o
            </Letter>
            <Letter className="coolAbout" index={3}>
              u
            </Letter>
            <Letter className="coolAbout" index={4}>
              t
            </Letter>
          </h2>
          <p
            className={isVisible ? "anim-delay2 slideIn-left" : ""}
            ref={imgRef}
          >
            Hey, my name is Marcos Fraga. I'm a Software developer passionate about
            creating engaging user experiences. Based in Lausanne, Switzerland,
            I thrive on innovative design and development. With a keen interest
            in new technologies and creative coding, I'm always curious to learn
            more. Outside of work, you'll find me immersed in the world of
            gaming and grooving to drum'n'bass beats. <br />
            Let's connect and collaborate on exciting projects!
          </p>
          <div
            className={`btnContainer ${
              isVisible ? "anim-delay3 slideIn-left" : ""
            }`}
            ref={imgRef}
          >
            <Button url="https://marcosfraga.ch/resume/MFRAGA-ENG.pdf">
              <FaDownload />
              <div>
                <span>Resume</span>
              </div>
            </Button>
            <div className="award-container">
            <img src={awardImg} alt="Best Solution Award 2023"/>
          </div>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </section>
  );
};

export default AboutPage;
