import React from "react";
import "./Layout.css";
import MFLogo from "../img/MARCOS W.png";
import { Link } from "react-router-dom";
import Footer from "../UI/Footer";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Layout = ({
  children,
  projectDescription,
  projectLogo,
  projectTitle,
  projectOverview,
  linkToWebsite,
  techIcons,
  background,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const addClassToBody = setTimeout(() => {
      document.body.classList.add("overflowBodyP");
    });

    const removeClassFromBody = setTimeout(() => {
      document.body.classList.remove("overflowBodyP");
    }, 4000);

    return () => {
      clearTimeout(addClassToBody);
      clearTimeout(removeClassFromBody);
    };
  }, []);

  return (
    <>
      <div
        className="project-page"
        style={{
          background: `#151a28 url(${background}) fixed center no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <Link to="/">
            <img className="mfLogo" src={MFLogo} alt="MarcosFraga Logo"></img>
          </Link>
          <main className="layoutContainer">
            <div className="projectOverviewHeader">
              <a href={linkToWebsite} target="_blank" rel="noreferrer">
                <img
                  className="project-img"
                  src={projectOverview}
                  alt="Project overview"
                ></img>
              </a>
            </div>
            <span className="icons">{techIcons}</span>
            <section className="project-header">
              <h2>{projectTitle}</h2>
              <img src={projectLogo} alt="Project logo"></img>
            </section>
            <section className="project-description">
              <div className="project-showcase">{children}</div>
              <p className="description">
                {projectDescription.map((paragraph, index) => {
                  if (paragraph === " ") {
                    return <br key={index} />;
                  } else {
                    return (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                      </React.Fragment>
                    );
                  }
                })}
              </p>
            </section>
            <div className="backButton-container">
              <a className="backButton" href="/">
                <span>Go back</span> <FaArrowCircleLeft />
              </a>
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
