import Layout from "./Layout";
import Logo from "./images/MF/MF logo.png";
import prjOverview from "./images/MF/MF Project Overview.webp";
import { FaHtml5, FaCss3Alt, FaJs, FaReact } from "react-icons/fa6";
import Background from "./images/MF/MFbground.webp";
import LoadingProject from "../UI/Animations/LoadingProject";
import MFdesk1 from "./images/MF/MFdesk1.webp"
import MFdesk2 from "./images/MF/MFdesk2.webp"
import MFdesk3 from "./images/MF/MFdesk3.webp"
import MFdesk4 from "./images/MF/MFdesk4.webp"
import MFdesk5 from "./images/MF/MFdesk5.webp"
import MFdesk6 from "./images/MF/MFdesk6.webp"
import MFdesk7 from "./images/MF/MFdesk7.webp"
import MFdesk8 from "./images/MF/MFdesk8.webp"
import MFmobile from "./images/MF/MFmobile.webp"

const MFproject = () => {
  const MFDescription = [
    "This project is a React-based web application that combines functionality with visual appeal. With a user-centric approach, the project includes a range of dynamic and attractive features.",
    " ",
    " ",
    "The key highlights are as follows:",
    " ",
    "   - Responsive navbar that expands and collapses.",
    " ",
    "   - Dynamic and eye-catching effects to create an engaging experience.",
    " ",
    "   - Smooth animations and transitions to add a touch of interactivity.",
    " ",
    "   - Interactive components to provide intuitive navigation.",
    " ",
    "   - Customization of the user interface to convey the brand or project's visual identity.",
    " ",
    "   - Optimized for high performance.",
    " ",
    "   - Optimized for SEO to improve search engine visibility",
    " ",
    " ",
    "These additional elements, including the responsive navbar, ensure that the project is accessible and enjoyable on all devices, delivering a consistent and user-friendly experience across different platforms. The focus on high performance ensures that the website loads quickly and provides a seamless browsing experience for users.",
  ];

  return (
    <>
      <LoadingProject />
      <Layout
        projectLogo={Logo}
        projectTitle="Full Web Development"
        projectOverview={prjOverview}
        projectDescription={MFDescription}
        techIcons={
          <>
            <FaHtml5 title="HTML" />
            <FaCss3Alt title="CSS" />
            <FaJs title="JavaScript" />
            <FaReact title="React" />
          </>
        }
        background={Background}
      >
        <h3>Homepage</h3>
        <img src={MFdesk1} alt="MF Homepage1" />
        <img src={MFdesk2} alt="MF Homepage2" />
        <img src={MFdesk3} alt="MF Homepage3" />
        <h3>About section</h3>
        <img src={MFdesk4} alt="MF About" />
        <h3>Portfolio section</h3>
        <img src={MFdesk5} alt="MF Portfolio" />
        <h3>Contact section</h3>
        <img src={MFdesk6} alt="MF Contact" />
        <h3>Projects showcase page</h3>
        <img src={MFdesk7} alt="MF Project showcase1" />
        <img src={MFdesk8} alt="MF Project showcase2" />
        <h3>Mobile</h3>
        <img src={MFmobile} alt="MF Mobile" />
      </Layout>
    </>
  );
};

export default MFproject;
