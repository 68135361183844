import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  FaGithub,
  FaLinkedin,
  FaCodepen,
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./Navbar.css";
import LogoExpanded from "../img/MARCOS W.webp";
import Logo from "../img/LOGO MF W.webp";

const Navbar = () => {
  const [delayEnded, setDelayEnded] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [navbarMounted, setNavbarMounted] = useState(false);

  const handleMouseEnter = () => {
    if ((!isTouchDevice && !isMobile) || (isTouchDevice && isMobile && expanded)) {
      setExpanded(true);
    }
  };  

  const handleMouseLeave = () => {
    if ((!isTouchDevice && !isMobile) || (isTouchDevice && isMobile && expanded)) {
      setExpanded(false);
    }
  };

  const handleCloseNavbar = () => {
    setExpanded(false);
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleExpandClick = () => {
    !expanded && setExpanded(true);
  };

  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const checkTouchDevice = () => {
      if (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      ) {
        setIsTouchDevice(true);
      }
    };

    checkTouchDevice();

    return () => {
      window.removeEventListener("touchstart", checkTouchDevice);
    };
  }, []);

  const handleSmoothScroll = (targetId, offset) => (event) => {
    event.preventDefault();

    const targetElement = document.querySelector(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - offset,
      });
    }
  };

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDelayEnded(true);
    }, 4500);

    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    const delayTimerTwo = setTimeout(() => {
      setNavbarMounted(true);
    }, 5000);

    return () => clearTimeout(delayTimerTwo);
  }, []);

  return (
    <>{delayEnded && (
      <>
      <div
        className={`${navbarMounted ? 'navbarMounted' : 'navbar'} ${expanded ? "expanded" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleExpandClick}
      >
        {/* Conteúdo da navbar */}
        <div className="navbar-content">
          {expanded && (isMobile || isTouchDevice) && (
            <FaArrowLeft className="close-button" onClick={handleCloseNavbar} />
          )}
          <div className="navbar-logo">
            {expanded ? (
              <img
                src={LogoExpanded}
                alt="Marcos Fraga Logo"
                className={`logo logo-expanded ${
                  expanded ? "logo-fadeIn" : "logo-fadeOut"
                }`}
              />
            ) : (
              <img
                src={Logo}
                alt="M F Logo"
                className={`logo logo-mini ${
                  expanded ? "logo-fadeOut" : "logo-fadeIn"
                }`}
              />
            )}
          </div>
          <div className="navbar-links">
            {expanded ? (
              <>
                <span className={`navbar-link txtSlideIn`}>
                  <a href="/#about" onClick={(event) => { handleSmoothScroll('#about', 50)(event); handleCloseNavbar(); }}>About Me</a>
                </span>
                <span className={`navbar-link txtSlideIn`}>
                  <a href="/#portfolio" onClick={handleCloseNavbar}>My Portfolio</a>
                </span>
                <span className={`navbar-link txtSlideIn`}>
                  <a href="/#contact" onClick={handleCloseNavbar}>Contact Me</a>
                </span>
              </>
            ) : (
              <div className="navbar-mug">
                <FaArrowRight onClick={handleExpandClick} />
              </div>
            )}
          </div>
          <div className={`navbar-icons ${expanded ? "expandedAlign" : null}`}>
            <a
              href="https://github.com/SOCRAMBLLE/"
              target="_blank"
              rel="noreferrer"
              onClick={handleCloseNavbar}
              aria-label="See Marcos's Projects on GitHub"
            >
              <FaGithub />
              {expanded ? (
                <p className={expanded ? "txtSlideIn" : null}>/socramblle</p>
              ) : null}
            </a>
            <a
              href="https://www.linkedin.com/in/marcosfraga/"
              target="_blank"
              rel="noreferrer"
              onClick={handleCloseNavbar}
              aria-label="Marcos's LinkedIn Profile"
            >
              <FaLinkedin />
              {expanded ? (
                <p className={expanded ? "txtSlideIn" : null}>/in/marcosfraga</p>
              ) : null}
            </a>
            <a
              href="https://twitter.com/mfragaprt"
              target="_blank"
              rel="noreferrer"
              onClick={handleCloseNavbar}
              aria-label="See Marcos's Twitter Profile"
            >
              <FaXTwitter />
              {expanded ? (
                <p className={expanded ? "txtSlideIn" : null}>/mfragaprt</p>
              ) : null}
            </a>
            <a
              href="https://codepen.io/SOCRAMBLLE"
              target="_blank"
              rel="noreferrer"
              onClick={handleCloseNavbar}
              aria-label="See Marcos's CodePen Profile"
            >
              <FaCodepen />
              {expanded ? (
                <p className={expanded ? "txtSlideIn" : null}>/socramblle</p>
              ) : null}
            </a>
          </div>
        </div>
      </div>
    </>
    )}
    </>
  );
};

export default Navbar;
