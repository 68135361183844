import "./Footer.css";
import Logo from "../img/LOGO MF W.webp";

const Footer = () => {

  return (
    <footer>
      <div className="footerContainer">
        <img src={Logo} alt="MF Logo"></img>
        <span>© 2023 - 2024 Marcos Fraga</span>
      </div>
    </footer>
  );
};

export default Footer;
