import Layout from "./Layout";
import Logo from "./images/Azulejo/Azulejo.webp";
import prjOverview from "./images/Azulejo/project-overview-65b2d7628574e.webp";
import Background from "./images/AM/AMbground.webp";
import LoadingProject from "../UI/Animations/LoadingProject";
import AZImmo1 from "./images/Azulejo/azulejo-homepage.webp";
import AZImmo2 from "./images/Azulejo/azulejo-about-page.webp";
import AZImmo3 from "./images/Azulejo/azulejo-services-page.webp";
import AZImmo4 from "./images/Azulejo/azulejo-contact-page.webp";
import AZImmoMobile from "./images/Azulejo/azulejo-mobile.webp";

const AzulejoIMMO = () => {
  const AzulejoDescription = [
    "This project is a website created without coding, tailored to the client's request for easy future content modifications. Built using no-code technologies, it provides a user-friendly interface and simplified content management.",
    " ",
    " ",
    "Key highlights of this project include:",
    " ",
    "   - Easy navigation interface to ensure a seamless browsing experience.",
    " ",
    "   - Visually appealing design showcasing Azulejo Immo's services.",
    " ",
    "   - SEO optimization following best practices to enhance search engine visibility.",
    " ",
    "   - Implementation of a contact form to facilitate communication with potential clients.",
    " ",
    "   - Adaptability and responsiveness for an optimal user experience across all devices.",
    " ",
    "   - Creation of the Hostinger platform account and setup of email services with the client's chosen domain.",
    " ",
    "Ongoing development to discuss and implement new details, ensuring an enhanced user experience.",
    " ",
  ];

  return (
    <>
      <LoadingProject />
      <Layout
        projectLogo={Logo}
        projectTitle="No coding website"
        projectOverview={prjOverview}
        projectDescription={AzulejoDescription}
        background={Background}
        linkToWebsite="https://azulejoimmo.com"
      >
        <h3>Homepage</h3>
        <img src={AZImmo1} alt="Homepage" />
        <h3>About page</h3>
        <img src={AZImmo2} alt="Azulejo Immo About Page" />
        <h3>Services pages</h3>
        <img src={AZImmo3} alt="Azulejo Immo Services Page" />
        <h3>Contact page</h3>
        <img src={AZImmo4} alt="Azulejo Immo Contact Page" />
        <h3>Mobile</h3>
        <img src={AZImmoMobile} alt="Azulejo Immo Mobile Version" />
      </Layout>
    </>
  );
};

export default AzulejoIMMO;
