import "./Card.css";
import { FaCodepen, FaGithub, FaLink, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";
import { Link } from "react-router-dom";

const Card = ({
  className,
  coverImgSrc,
  projectImgSrc,
  CodePenLink,
  GitHubLink,
  RenderLink,
  prjDetailsPage
}) => {
  return (
    <>
      <div className={`card ${className}`}>
        <div className="wrapper">
          <img src={coverImgSrc} className="cover-image" alt="logo" />
        </div>
        <div className="prjLinks">
          <Link className="prjLink github" to={GitHubLink} target="_blank">
            <FaGithub />
          </Link>
          <Link className="prjLink codepen" to={CodePenLink} target="_blank">
            <FaCodepen />
          </Link>
          <Link className="prjLink link" to={RenderLink} target="_blank">
            <FaLink />
          </Link>
          <Link className="prjLink prjDetailsLink" to={prjDetailsPage}>
            <BiDetail />
          </Link>
          <Link className="prjLink prjPageLink" to="/projects/OtherProjects">
            <FaAngleRight />Take a look<FaAngleLeft />
          </Link>
        </div>
        <img
          src={projectImgSrc}
          className="projectImg"
          alt="project overview"
        />
      </div>
    </>
  );
};

export default Card;
