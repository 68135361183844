import Layout from "./Layout";
import logo from "./images/LawPaw/lawpaw-logo.webp";
import prjOverview from "./images/LawPaw/LawPaw Project Overview.webp";
import {
  FaReact,
  FaCss3Alt,
  FaJs,
  FaPython,
  FaDocker,
  FaFigma,
  FaCircleInfo,
} from "react-icons/fa6";
import { RiOpenaiFill } from "react-icons/ri";
import Background from "./images/LawPaw/bground.webp";
import LoadingProject from "../UI/Animations/LoadingProject";
import LawPawOV1 from "./images/LawPaw/LawPawOV1.webp";
import LawPawOV2 from "./images/LawPaw/LawPawOV2.webp";
import LawPawOV3 from "./images/LawPaw/LawPawOV3.webp";
import LawPawOV4 from "./images/LawPaw/LawPawOV4.webp";
import LawPawOV5 from "./images/LawPaw/LawPawOV5.webp";
import LawPawOV6 from "./images/LawPaw/LawPawOV6.webp";
import awardImg from "../img/award7.png";

const LawPawProject = () => {
  const LawPawDescription = [
    "LawPaw is a dynamic project that emerged as the Best Solution 2023 for the Ginetta Challenge at the Hack'N'Lead Zurich 2023. As a Front-End developer, I played a pivotal role in bringing this innovative ChatBot AI to life, transforming the landscape of accessing Swiss private law.",
    " ",
    " ",
    "Key Project Features:",
    " ",
    "   - Integration of LLM-GPT (OpenAI), a state-of-the-art language model, enabling the ChatBot to comprehend and respond intelligently to legal queries.",
    " ",
    "   - Frontend development using React.js, ensuring a seamless and user-friendly interface for individuals without legal expertise.",
    " ",
    "   - Collaboration with a multidisciplinary team of designers and backend developers to achieve a cohesive and high-performing solution.",
    " ",
    "   - A user-centric approach to make complex legal information accessible, providing personalized responses beyond the capabilities of conventional search engines.",
    " ",
    "   - Utilization of Python for the backend, contributing to the robust functionality and logic of the ChatBot.",
    " ",
    "   - A solution that addresses the limitations of generic online searches, offering personalized and cost-effective legal insights.",
    " ",
    " ",
    "Throughout this project, I had the privilege of collaborating with talented designers and backend developers, fostering an environment of creativity and innovation. The multidisciplinary teamwork was crucial in overcoming challenges and delivering a groundbreaking ChatBot that redefines the accessibility of legal information in the Swiss context.",
    " ",
    "Recently, our team has expanded its impact by joining a project with Jurata, a leading legal support company in Switzerland, to create a Law Bot. This collaboration further extends our commitment to revolutionizing the legal tech landscape.",
    " ",
  ];

  return (
    <>
      <LoadingProject />
      <Layout
        projectLogo={logo}
        projectTitle="Front-end Development"
        projectOverview={prjOverview}
        projectDescription={LawPawDescription}
        linkToWebsite="https://github.com/SL1015/LawPaw"
        techIcons={
          <>
            <FaReact title="React.js" />
            <FaCss3Alt title="CSS" />
            <FaJs title="JavaScript" />
            <FaPython title="Python" />
            <FaFigma title="Figma" />
            <RiOpenaiFill title="OpenAI" />
            <FaDocker title="Docker" />
          </>
        }
        background={Background}
      >
        <div className="award-img-container">
          <img src={awardImg} alt="Best Solution Award 2023" />
          <h3>
            You can access to a preview app of LawPaw{" "}
            <a
              href="https://law-paw.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .{" "}
            <span
              className="info-hover-txt"
              data-hover="This is a preview of the front end UI. For now, the backend isn't connected, so the chat function will not work."
            >
              <FaCircleInfo />
            </span>
          </h3>
        </div>
        <h3>Homepage</h3>
        <img src={LawPawOV1} alt="FTC Homepage" />
        <h3>Language Selection page</h3>
        <img src={LawPawOV2} alt="FTC About Page" />
        <h3>Jurisdiction Selection page</h3>
        <img src={LawPawOV3} alt="FTC Projects Page" />
        <h3>Legal Category page</h3>
        <img src={LawPawOV4} alt="FTC Recruitment Page" />
        <h3>Chat Bot page</h3>
        <img src={LawPawOV5} alt="FTC Contact Page" />
        <h3>Settings menu</h3>
        <img src={LawPawOV6} alt="FTC Contact Page" />
      </Layout>
    </>
  );
};

export default LawPawProject;
