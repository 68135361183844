import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/UI/Navbar";
import Background from "./components/UI/Background";
import Header from "./components/Pages/Header";
import AboutPage from "./components/Pages/AboutPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import ContactPage from "./components/Pages/ContactPage";
import Footer from "./components/UI/Footer";
import Loading from "./components/UI/Animations/Loading";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  return (
    <div className="App">
      <Loading />
      {!isLoading && (
        <>
          <Background />
          <Navbar />
          <Header />
        </>
      )}
      <AboutPage />
      <PortfolioPage />
      <ContactPage />
      <Footer />
    </div>
  );
}

export default App;
