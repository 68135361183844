import "./PCard.css";
import { FaHandPointer } from "react-icons/fa";
import { Link } from "react-router-dom";

const PCard = ({ className, children, coverImgSrc, projectImgSrc, projectLink, icon1, icon2, icon3, icon4 }) => {
  return (
    <>
      <div className={`pCard ${className}`}>
        <div className="wrapper">
          <img src={coverImgSrc} className="cover-image" alt="logo" />
        </div>
        <span className="title">{children}</span>
        <Link className="prjLink" to={projectLink}>
          Take a look <FaHandPointer />
        </Link>
        <img
          src={projectImgSrc}
          className="projectImg"
          alt="project overview"
        />
        <div class="circle">
          <img src={icon1} alt="icon"></img>
          <img src={icon2} alt="icon"></img>
          <img src={icon3} alt="icon"></img>
          <img src={icon4} alt="icon"></img>
        </div>
      </div>
    </>
  );
};

export default PCard;
