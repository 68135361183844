import "./DownloadButton.css"

const Button = ({ children, url }) => {
    const handleDownload = () => {
        window.open(url, "_blank");
    }
    return (
        <button onClick={handleDownload}>{children}</button>
    )
}

export default Button;