import styles from "./Particules.module.css";

const Particules = () => {
  return (
    <>
      <div className={styles.backgroundP}>
        <div className={styles.pparticules}>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 15 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 10 }}></span>
          <span style={{ '--i' : 24 }}></span>
          <span style={{ '--i' : 20 }}></span>
          <span style={{ '--i' : 23 }}></span>
          <span style={{ '--i' : 13 }}></span>
          <span style={{ '--i' : 17 }}></span>
          <span style={{ '--i' : 22 }}></span>
          <span style={{ '--i' : 14 }}></span>
          <span style={{ '--i' : 28 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 19 }}></span>
          <span style={{ '--i' : 25 }}></span>
          <span style={{ '--i' : 30 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 37 }}></span>
          <span style={{ '--i' : 8 }}></span>
          <span style={{ '--i' : 4 }}></span>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 15 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 10 }}></span>
          <span style={{ '--i' : 24 }}></span>
          <span style={{ '--i' : 20 }}></span>
          <span style={{ '--i' : 23 }}></span>
          <span style={{ '--i' : 18 }}></span>
          <span style={{ '--i' : 17 }}></span>
          <span style={{ '--i' : 22 }}></span>
          <span style={{ '--i' : 14 }}></span>
          <span style={{ '--i' : 28 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 19 }}></span>
          <span style={{ '--i' : 27 }}></span>
          <span style={{ '--i' : 25 }}></span>
          <span style={{ '--i' : 30 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 37 }}></span>
          <span style={{ '--i' : 8 }}></span>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 15 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 10 }}></span>
          <span style={{ '--i' : 24 }}></span>
          <span style={{ '--i' : 20 }}></span>
          <span style={{ '--i' : 18 }}></span>
          <span style={{ '--i' : 13 }}></span>
          <span style={{ '--i' : 17 }}></span>
          <span style={{ '--i' : 22 }}></span>
          <span style={{ '--i' : 14 }}></span>
          <span style={{ '--i' : 28 }}></span>
          <span style={{ '--i' : 19 }}></span>
          <span style={{ '--i' : 27 }}></span>
          <span style={{ '--i' : 25 }}></span>
          <span style={{ '--i' : 30 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 37 }}></span>
          <span style={{ '--i' : 8 }}></span>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 15 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 10 }}></span>
          <span style={{ '--i' : 24 }}></span>
          <span style={{ '--i' : 20 }}></span>
          <span style={{ '--i' : 23 }}></span>
          <span style={{ '--i' : 18 }}></span>
          <span style={{ '--i' : 13 }}></span>
          <span style={{ '--i' : 22 }}></span>
          <span style={{ '--i' : 14 }}></span>
          <span style={{ '--i' : 28 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 19 }}></span>
          <span style={{ '--i' : 27 }}></span>
          <span style={{ '--i' : 25 }}></span>
          <span style={{ '--i' : 30 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 37 }}></span>
          <span style={{ '--i' : 4 }}></span>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 11 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 15 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 24 }}></span>
          <span style={{ '--i' : 20 }}></span>
          <span style={{ '--i' : 23 }}></span>
          <span style={{ '--i' : 18 }}></span>
          <span style={{ '--i' : 13 }}></span>
          <span style={{ '--i' : 17 }}></span>
          <span style={{ '--i' : 22 }}></span>
          <span style={{ '--i' : 14 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 19 }}></span>
          <span style={{ '--i' : 27 }}></span>
          <span style={{ '--i' : 25 }}></span>
          <span style={{ '--i' : 30 }}></span>
          <span style={{ '--i' : 12 }}></span>
          <span style={{ '--i' : 21 }}></span>
          <span style={{ '--i' : 26 }}></span>
          <span style={{ '--i' : 8 }}></span>
          <span style={{ '--i' : 4 }}></span>
          <span style={{ '--i' : 11 }}></span>
        </div>
      </div>
    </>
  );
};

export default Particules;
