import React, { useEffect, useRef, useState } from "react";
import "./PortfolioPage.css";
import Letter from "../UI/Animations/LetterAnimation";
import PCard from "../UI/PCard";
import Card from "../UI/Card";
import FTClogo from "../img/logoFTCconstru.webp";
import FTCProjectOverview from "../img/FTC Project Overview.webp";
import AMlogo from "../img/AM logo.webp";
import AMProjectOverview from "../img/AM Project Overview.webp";
import MFlogo from "../img/MARCOS W.webp";
import MFProjectOverview from "../img/MF Project Overview.webp";
import ProjectPageLogo from "../img/projectspageimg.webp";
import ProjectPageOverview from "../img/Preview projects page.gif";
import LawPawLogo from "../img/lawpaw-logo.webp";
import LawPawProjectOverview from "../img/LawPaw Project Overview.webp";
import HTMLicon from "../projects/images/icons/HTML.png";
import CSSicon from "../projects/images/icons/CSS.png";
import JSicon from "../projects/images/icons/JS.png";
import REACTicon from "../projects/images/icons/React.png";
import JQicon from "../projects/images/icons/jQuery.png";
import BSicon from "../projects/images/icons/Bootstrap.png";
import FigmaIcon from "../projects/images/icons/Figma.png";
import OpenAiIcon from "../projects/images/icons/OpenAI.png";
import PythonIcon from "../projects/images/icons/Python.png";
import awardImg from "../img/award7.png"

const PortfolioPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imgReff = useRef(null);

  const handleScroll = () => {
    const element = imgReff.current;
    if (element) {
      const { top } = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const margin = 230;
      if (top < windowHeight - margin) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="portfolio" className="portfolio-page">
      <div className="portfolio-container">
        <div className="title-container">
          <h2 className={isVisible ? "slideIn-left" : ""} ref={imgReff}>
            <Letter className="coolPort" index={0}>
              M
            </Letter>
            <Letter className="coolPort" index={1}>
              y
            </Letter>
            <span className="coolPort space">*</span>
            <Letter className="coolPort" index={2}>
              W
            </Letter>
            <Letter className="coolPort" index={3}>
              o
            </Letter>
            <Letter className="coolPort" index={4}>
              r
            </Letter>
            <Letter className="coolPort" index={5}>
              k
            </Letter>
          </h2>
        </div>
        <div className="projects-container">
        <PCard
            className={`lawpawCard ${isVisible ? "anim-delay1 slideIn-bounce" : ""}`}
            ref={imgReff}
            coverImgSrc={LawPawLogo}
            projectImgSrc={LawPawProjectOverview}
            projectLink="/projects/LawPaw"
            icon1={REACTicon}
            icon2={FigmaIcon}
            icon3={OpenAiIcon}
            icon4={PythonIcon}
          >
            Front-end Development
            <img className="award-image" src={awardImg} alt="Best Solution Award 2023" />
          </PCard>
          <PCard
            className={isVisible ? "anim-delay2 slideIn-bounce" : ""}
            ref={imgReff}
            coverImgSrc={FTClogo}
            projectImgSrc={FTCProjectOverview}
            projectLink="/projects/FTC"
            icon1={HTMLicon}
            icon2={CSSicon}
            icon3={JSicon}
            icon4={JQicon}
          >
            Full web development
          </PCard>
          <PCard
            className={isVisible ? "anim-delay3 slideIn-bounce" : ""}
            ref={imgReff}
            coverImgSrc={AMlogo}
            projectImgSrc={AMProjectOverview}
            projectLink="/projects/AM"
            icon1={REACTicon}
            icon2={CSSicon}
            icon3={JSicon}
            icon4={BSicon}
          >
            Full web development
          </PCard>
          <PCard
            className={isVisible ? "anim-delay4 slideIn-bounce" : ""}
            coverImgSrc={MFlogo}
            projectImgSrc={MFProjectOverview}
            projectLink="/projects/MarcosFraga"
            icon1={HTMLicon}
            icon2={CSSicon}
            icon3={JSicon}
            icon4={REACTicon}
          >
            Full web development
          </PCard>
        </div>
        <div className="github-projects">
          <h3>
            Explore my diverse range of projects, including small web apps and
            more.
          </h3>
          <Card
                  className={`prjPageLink ${isVisible ? "anim-delay5 slideIn-bounce" : ""}`}
                  coverImgSrc={ProjectPageLogo}
                  projectImgSrc={ProjectPageOverview}
                />
        </div>
      </div>
    </section>
  );
};

export default PortfolioPage;
