import React, { useEffect, useState } from 'react';
import Letter from "../UI/Animations/LetterAnimation";
import ScrollIcon from '../UI/ScrollDownIcon';
import "./Header.css";

const Header = (props) => {
  const [delayEnded, setDelayEnded] = useState(false);
  const [delayEndedTwo, setDelayEndedTwo] = useState(false);
  const [animateLetters, setAnimateLetters] = useState(false);

  useEffect(() => {
    setAnimateLetters(true);
  }, []);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDelayEnded(true);
    }, 2500);

    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    const delayTimerTwo = setTimeout(() => {
      setDelayEndedTwo(true);
    }, 5500);

    return () => clearTimeout(delayTimerTwo);
  }, []);

  return (
    <>
      <section id='top' className="header-section">
        {delayEnded && (
          <>
          <div className="helloTxt">
          <div className="introText">
            <h1 className={`name ${animateLetters ? 'animate' : ''}`}>
              <div><Letter className="cool" index={1}>M</Letter></div>
              <div><Letter className="cool" index={2}>a</Letter></div>
              <div><Letter className="cool" index={3}>r</Letter></div>
              <div><Letter className="cool" index={4}>c</Letter></div>
              <div><Letter className="cool" index={5}>o</Letter></div>
              <div><Letter className="cool" index={6}>s</Letter></div>
              <span className="cool space">&nbsp;</span>
              <div><Letter className="cool" index={7}>F</Letter></div>
              <div><Letter className="cool" index={8}>r</Letter></div>
              <div><Letter className="cool" index={9}>a</Letter></div>
              <div><Letter className="cool" index={10}>g</Letter></div>
              <div><Letter className="cool" index={11}>a</Letter></div>
            </h1>
            <h1 className="prof">
            {/* <div><Letter className="cool" index={12}>W</Letter></div>
            <div><Letter className="cool" index={13}>e</Letter></div>
            <div><Letter className="cool" index={14}>b</Letter></div>
              <span className="cool space">*</span>
              <div><Letter className="cool" index={15}>D</Letter></div>
              <div><Letter className="cool" index={16}>e</Letter></div>
              <div><Letter className="cool" index={17}>v</Letter></div>
              <div><Letter className="cool" index={18}>e</Letter></div>
              <div><Letter className="cool" index={19}>l</Letter></div>
              <div><Letter className="cool" index={20}>o</Letter></div>
              <div><Letter className="cool" index={21}>p</Letter></div>
              <div><Letter className="cool" index={22}>e</Letter></div>
              <div><Letter className="cool" index={23}>r</Letter></div> */}
              <div><Letter className="cool" index={12}>&lt;</Letter></div>
              <div><Letter className="cool" index={13}>c</Letter></div>
              <div><Letter className="cool" index={14}>o</Letter></div>
              <div><Letter className="cool" index={15}>d</Letter></div>
              <div><Letter className="cool" index={16}>e</Letter></div>
              <div><Letter className="cool" index={17}>r</Letter></div>
              <span className="cool space">&nbsp;</span>
              <div><Letter className="cool" index={18}>/</Letter></div>
              <div><Letter className="cool" index={19}>&gt;</Letter></div>
            </h1>
          </div>
          <div className="subTitleTxt">
            <h2 className="subtitle">
              Software Developer
            </h2>
          </div>
        </div>
        {delayEndedTwo && <ScrollIcon />}
        </>
        )}
      </section>
    </>
  );
};

export default Header;
