import LoadingProject from "../UI/Animations/LoadingProject";
import "./OtherProjects.css";
import MFLogo from "../img/MARCOS W.png";
import { Link, useLocation } from "react-router-dom";
import Footer from "../UI/Footer";
import { FaArrowCircleLeft } from "react-icons/fa";
import Card from "../UI/Card";
import dElement from "./images/OtherProjects/preview-dynamic-3d.gif";
import dElementlogo from "./images/OtherProjects/3dElement.webp";
import popElementLogo from "./images/OtherProjects/PopElement.webp";
import popElement from "./images/OtherProjects/preview-popup-element.gif";
import flexGalleryLogo from "./images/OtherProjects/FlexGallery.webp";
import flexGallery from "./images/OtherProjects/preview-dynamic-gallery.gif";
import responsiveNavbarLogo from "./images/OtherProjects/responsiveNavbar.webp";
import responsiveNavbar from "./images/OtherProjects/preview-background-resp-navbar.gif";
import toDoListApp from "./images/OtherProjects/toDoListApp.webp";
import toDoListLogo from "./images/OtherProjects/toDoListLogo.webp";
import invCalcApp from "./images/OtherProjects/InvCalcAPP.webp";
import invCalcLogo from "./images/OtherProjects/InvLogo.webp";
import coolCardLogo from "./images/OtherProjects/coolcardlogo.webp";
import coolCard from "./images/OtherProjects/CoolCard.gif";
import azulejoLogo from "./images/OtherProjects/Azulejo.png";
import azulejoPreview from "./images/OtherProjects/azulejoPreview.gif";
import automationTollLogo from "./images/OtherProjects/automation-tool.webp";
import automationToll from "./images/OtherProjects/preview-automation-script.gif";
import vanLifeLogo from "./images/OtherProjects/van-life-logo.png";
import vanLifePreview from "./images/OtherProjects/van-life-preview.gif";
import tenziesLogo from "./images/OtherProjects/tenzies.png";
import tenziesPreview from "./images/OtherProjects/tenzies-preview.gif";
import memeGenLogo from "./images/OtherProjects/memeGen.png";
import memeGenPreview from "./images/OtherProjects/memeGen-preview.gif";
import markdownNotes from "./images/OtherProjects/markdownNotes.png";
import markdownNotesPreview from "./images/OtherProjects/markdownNotes-preview.gif";
import { FaCodepen, FaGithub } from "react-icons/fa";
import { useEffect } from "react";

const OtherProjects = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <LoadingProject />
      <div className="project-page">
        <div className="container">
          <Link to="/">
            <img className="mfLogo" src={MFLogo} alt="MarcosFraga Logo"></img>
          </Link>

          <h3 className="gitProjectsText">
            Here, you'll find a collection of my small projects, web apps, and
            CSS animations. I love creating and experimenting with different
            technologies to bring ideas to life. Whether it's a simple web app
            or a fun CSS animation, each project represents a learning journey
            and a chance to explore new possibilities in the world of web
            development.
          </h3>
          <main className="layoutContainer">
            <div className="gitProjects-container">
              <section className="gitProject">
                <h2>Azulejo IMMO</h2>
                <Card
                  className="link prjDetails"
                  coverImgSrc={azulejoLogo}
                  projectImgSrc={azulejoPreview}
                  RenderLink="https://azulejoimmo.com"
                  prjDetailsPage="/projects/AzulejoIMMO"
                />
              </section>
              <section className="gitProject">
                <h2>Van Life</h2>
                <Card
                  className="link github"
                  coverImgSrc={vanLifeLogo}
                  projectImgSrc={vanLifePreview}
                  RenderLink="https://van-life-psi.vercel.app/"
                  GitHubLink="https://github.com/SOCRAMBLLE/van-life"
                />
              </section>
              <section className="gitProject">
                <h2>Markdown notes</h2>
                <Card
                  className="link github"
                  coverImgSrc={markdownNotes}
                  projectImgSrc={markdownNotesPreview}
                  RenderLink="https://azulejoimmo.com"
                  GitHubLink="https://github.com/SOCRAMBLLE/markdown-notes-app"
                />
              </section>
              <section className="gitProject">
                <h2>Meme generator</h2>
                <Card
                  className="link github"
                  coverImgSrc={memeGenLogo}
                  projectImgSrc={memeGenPreview}
                  RenderLink="https://azulejoimmo.com"
                  GitHubLink="https://github.com/SOCRAMBLLE/meme-generator"
                />
              </section>
              <section className="gitProject">
                <h2>Tenzies game</h2>
                <Card
                  className="link github"
                  coverImgSrc={tenziesLogo}
                  projectImgSrc={tenziesPreview}
                  RenderLink="https://azulejoimmo.com"
                  GitHubLink="https://github.com/SOCRAMBLLE/tenzies-game"
                />
              </section>
              <section className="gitProject">
                <h2>Pop up element</h2>
                <Card
                  className="github codepen"
                  coverImgSrc={popElementLogo}
                  projectImgSrc={popElement}
                  CodePenLink="https://codepen.io/SOCRAMBLLE/pen/xxaeppY"
                  GitHubLink="https://github.com/SOCRAMBLLE/slide-up-box-on-hover"
                />
              </section>
              <section className="gitProject">
                <h2>3D element</h2>
                <Card
                  className="github codepen"
                  coverImgSrc={dElementlogo}
                  projectImgSrc={dElement}
                  CodePenLink="https://codepen.io/SOCRAMBLLE/pen/wvEZpGb"
                  GitHubLink="https://github.com/SOCRAMBLLE/Dynamic-3D-Element"
                />
              </section>
              <section className="gitProject">
                <h2>Flex image gallery</h2>
                <Card
                  className="github codepen"
                  coverImgSrc={flexGalleryLogo}
                  projectImgSrc={flexGallery}
                  CodePenLink="https://codepen.io/SOCRAMBLLE/pen/JjaVMyp"
                  GitHubLink="https://github.com/SOCRAMBLLE/Cool-image-gallery-with-flex---CSS-only"
                />
              </section>
              <section className="gitProject">
                <h2>Responsive color navbar</h2>
                <Card
                  className="github codepen"
                  coverImgSrc={responsiveNavbarLogo}
                  projectImgSrc={responsiveNavbar}
                  CodePenLink="https://codepen.io/SOCRAMBLLE/pen/vYzMprb"
                  GitHubLink="https://github.com/SOCRAMBLLE/Responsive-NavBar-Background"
                />
              </section>
              <section className="gitProject">
                <h2>Card with hover effect</h2>
                <Card
                  className="github codepen"
                  coverImgSrc={coolCardLogo}
                  projectImgSrc={coolCard}
                  CodePenLink="https://codepen.io/SOCRAMBLLE/pen/PoxgEXx"
                  GitHubLink="https://github.com/SOCRAMBLLE/Card-with-cool-hover-effect/"
                />
              </section>
              <section className="gitProject">
                <h2>To do list App</h2>
                <Card
                  className="github link"
                  coverImgSrc={toDoListLogo}
                  projectImgSrc={toDoListApp}
                  RenderLink="https://todolist-wfae.onrender.com"
                  GitHubLink="https://github.com/SOCRAMBLLE/to-do-list-app"
                />
              </section>
              <section className="gitProject">
                <h2>Investment calculator App</h2>
                <Card
                  className="github link"
                  coverImgSrc={invCalcLogo}
                  projectImgSrc={invCalcApp}
                  RenderLink="https://investment-calculator.onrender.com"
                  GitHubLink="https://github.com/SOCRAMBLLE/InvestmentCalculator"
                />
              </section>
              <section className="gitProject">
                <h2>Workflow automation tool</h2>
                <Card
                  className="github"
                  coverImgSrc={automationTollLogo}
                  projectImgSrc={automationToll}
                  GitHubLink="https://github.com/SOCRAMBLLE/Desktop-Automation"
                />
              </section>
            </div>
            <div className="linksProjects">
              <h3>
                Feel free to explore and check out the various projects I've
                worked on.
              </h3>
              <h3>Follow me:</h3>
              <div className="linksIcons">
                <a
                  href="https://github.com/SOCRAMBLLE/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub />
                </a>
                <a
                  href="https://codepen.io/SOCRAMBLLE"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaCodepen />
                </a>
              </div>
            </div>
            <div className="backButton-container">
              <a className="backButton" href="/">
                <span>Go back</span> <FaArrowCircleLeft />
              </a>
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default OtherProjects;
