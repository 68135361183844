import Layout from "./Layout";
import logo from "./images/FTC/logoFTC.png";
import prjOverview from "./images/FTC/FTC Project Overview.png";
import { FaHtml5, FaCss3Alt, FaJs } from "react-icons/fa6";
import { BiLogoJquery } from "react-icons/bi";
import Background from "./images/FTC/FTCbground.webp";
import LoadingProject from "../UI/Animations/LoadingProject";
import FTCdesk1 from "./images/FTC/FTCdesk1.webp"
import FTCdesk2 from "./images/FTC/FTCdesk2.webp"
import FTCdesk3 from "./images/FTC/FTCdesk3.webp"
import FTCdesk4 from "./images/FTC/FTCdesk4.webp"
import FTCdesk5 from "./images/FTC/FTCdesk5.webp"
import FTCmobile from "./images/FTC/FTCmobile.webp"

const FTCproject = () => {
  const FTCDescription = [
    "This project is a website built using HTML, CSS, JavaScript, and jQuery. It focuses on creating a visually appealing website with a user-friendly interface.",
    " ",
    " ",
    "The key highlights of this project are as follows:",
    " ",
    "   - Visually appealing design that promotes a professional image.",
    " ",
    "   - Easy navigation interface to ensure a seamless browsing experience.",
    " ",
    "   - Gallery page featuring a collection of previous works to showcase the client's portfolio.",
    " ",
    "   - Dedicated recruitment page to attract potential talent and streamline the hiring process.",
    " ",
    "   - Responsive navbar that adapts its style based on the position on the screen.",
    " ",
    "   - Contact page to facilitate communication with potential customers that provide a way to reach out for the client's services.",
    " ",
    "   - Optimized for high performance.",
    " ",
    "   - Optimized for SEO to improve search engine visibility",
    " ",
    " ",
    "By leveraging HTML, CSS, JavaScript, and jQuery, this project delivers a visually appealing website with an intuitive navigation system. The inclusion of a gallery page allows the client to showcase their previous works, while the dedicated recruitment page helps attract talent. The responsive navbar ensures a seamless experience on different devices, and the contact page simplifies communication with potential customers. Overall, this project aims to create a compelling online presence for the client's business.",
    " ",
  ];

  return (
    <>
      <LoadingProject />
      <Layout
        projectLogo={logo}
        projectTitle="Full Web Development Project"
        projectOverview={prjOverview}
        projectDescription={FTCDescription}
        linkToWebsite="https://ftcconstru.site"
        techIcons={
          <>
            <FaHtml5 title="HTML" />
            <FaCss3Alt title="CSS" />
            <FaJs title="JavaScript" />
            <BiLogoJquery title="jQyert" />
          </>
        }
        background={Background}
      >
        <h3>Homepage</h3>
        <img src={FTCdesk1} alt="FTC Homepage" />
        <h3>About page</h3>
        <img src={FTCdesk2} alt="FTC About Page" />
        <h3>Projects showcase page</h3>
        <img src={FTCdesk3} alt="FTC Projects Page" />
        <h3>Recruitment page</h3>
        <img src={FTCdesk4} alt="FTC Recruitment Page" />
        <h3>Contact page</h3>
        <img src={FTCdesk5} alt="FTC Contact Page" />
        <h3>Mobile</h3>
        <img src={FTCmobile} alt="FTC Mobile" />
      </Layout>
    </>
  );
};

export default FTCproject;
