import React, { useRef } from 'react';
import "./LetterAnimation.css";

const Letter = ({ index, children, className }) => {
    const letterRef = useRef(null);
  
    const handleAnimationStart = () => {
      letterRef.current.classList.add("rubberBand");
    };
  
    const handleAnimationEnd = () => {
      letterRef.current.classList.remove("rubberBand");
    };
  
    return (
      <span
        ref={letterRef}
        className={className}
        onMouseEnter={handleAnimationStart}
        onAnimationEnd={handleAnimationEnd}
      >
        {children}
      </span>
    );
  };

export default Letter;