import React, { useRef, useState, useEffect } from "react";
import "./Background.css";
import BGvideo from "../img/bg.mp4";

const Background = () => {
  const videoRef = useRef(null);
  const [delayedStart, setDelayedStart] = useState(true);
  const [showBlurEffect, setShowBlurEffect] = useState(false);

  useEffect(() => {
    videoRef.current.preload = "auto";
    if (delayedStart) {
      const delayTimer = setTimeout(() => {
        setDelayedStart(false);
        videoRef.current.play();
      }, 1500);

      return () => clearTimeout(delayTimer);
    }
  }, [delayedStart]);

  useEffect(() => {
    if (!delayedStart) {
      const blurTimer = setTimeout(() => {
        setShowBlurEffect(true);
      }, 1600);

      return () => clearTimeout(blurTimer);
    }
  }, [delayedStart]);

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.removeEventListener("ended", handleVideoEnd);
      videoRef.current.pause();
    }
  };
  
  return (
    <>
      <div className="background">
        <div className={`videoOverlay ${showBlurEffect ? "blurEffect" : ""}`} />
        <video
          ref={videoRef}
          autoPlay={false}
          muted
          playsInline
          onEnded={handleVideoEnd}
          src={BGvideo}
          type="video/mp4"
          preload="metadata"
        />
        {showBlurEffect ? (
          <div className="particules">
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 15 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 10 }}></span>
            <i style={{ "--i": 15 }} className="fa-brands fa-square-js"></i>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 23 }}></span>
            <i style={{ "--i": 18 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 13 }}></span>
            <span style={{ "--i": 17 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 14 }}></span>
            <i style={{ "--i": 16 }} className="fa-brands fa-react"></i>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 19 }}></span>
            <i style={{ "--i": 27 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 30 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 37 }}></span>
            <span style={{ "--i": 8 }}></span>
            <span style={{ "--i": 4 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 15 }}></span>
            <span style={{ "--i": 21 }}></span>
            <i style={{ "--i": 8 }} className="fa-brands fa-css3-alt"></i>
            <span style={{ "--i": 10 }}></span>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 23 }}></span>
            <span style={{ "--i": 18 }}></span>
            <i style={{ "--i": 13 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 17 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 14 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 19 }}></span>
            <span style={{ "--i": 27 }}></span>
            <i style={{ "--i": 26 }} className="fa-brands fa-python"></i>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 30 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 37 }}></span>
            <span style={{ "--i": 8 }}></span>
            <i style={{ "--i": 4 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 15 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 10 }}></span>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 13 }}></span>
            <i style={{ "--i": 13 }} className="fa-brands fa-git"></i>
            <span style={{ "--i": 17 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 14 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 19 }}></span>
            <span style={{ "--i": 27 }}></span>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 30 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 37 }}></span>
            <span style={{ "--i": 8 }}></span>
            <i style={{ "--i": 4 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 15 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 10 }}></span>
            <i style={{ "--i": 18 }} className="fa-brands fa-node"></i>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 23 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 13 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 14 }}></span>
            <span style={{ "--i": 28 }}></span>
            <i style={{ "--i": 26 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 19 }}></span>
            <span style={{ "--i": 27 }}></span>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 30 }}></span>
            <i style={{ "--i": 11 }} className="fa-brands fa-bootstrap"></i>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 37 }}></span>
            <i style={{ "--i": 8 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 4 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 15 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 24 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 23 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 13 }}></span>
            <span style={{ "--i": 17 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 14 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 19 }}></span>
            <span style={{ "--i": 27 }}></span>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 30 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 26 }}></span>
            <i style={{ "--i": 37 }} className="fa-solid fa-mug-hot"></i>
            <span style={{ "--i": 8 }}></span>
            <span style={{ "--i": 4 }}></span>
            <span style={{ "--i": 11 }}></span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Background;
